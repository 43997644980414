<template>
    <div class="classWrapper">
        <top-search></top-search>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="breadCrump">
                        <span>
                            <router-link to="/">
                                Startseite
                            </router-link>
                        </span>
                        |
                        <span>
                           Fächer
                        </span>
                    </div>
                </div>
            </div>
            <div class="row" v-show="loadComplete == false">
                <div class="col-md-12">
                    <div class="loadingBox">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <br><br>
                                <b>Fächer werden geladen...</b>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="loadingBar" style="position: absolute;left:50%;top:15px;transform: translateX(-50%);">
                                    <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-show="loadComplete == true">
                <div class="col-md-12">
                    <div class="letterWraper" v-for="class_list in classes" :key="class_list.id" >
                        <div class="row">
                            <div class="col-sm-8">
                                <h4 style="text-transform: uppercase;">{{class_list.class.strSubject}} - {{class_list.class.name}}</h4>
                            </div>
                            <div class="col-sm-4 text-right">
                                <span>
                                    <router-link
                                            v-if="class_list.class.item_count > 3"
                                            :to="{ name: 'class', params: {
                                                cluster: class_list.class.formated_data,
                                                class: class_list.class.formated_subject
                                            }}">
                                        {{class_list.class.item_count - 3}} weitere Ansehen...
                                    </router-link>
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4" v-for="item in class_list.data" :key="item.id_item">
                                <div class="card" >
                                    <div class="card-body" >
                                        <h5 class="card-title">{{item.item_title | str_limit(20)}}</h5>
                                        <p class="card-text" style="min-height: 130px">{{item.item_plaintext | str_limit(150)}}</p>
                                        <div class="text-center">
                                            <span class="btn btn-primary ">
                                                <router-link :to="{ name: 'preview', params: {cluster: item.cluster_formated,class: item.class_1_formated,letter: item.item_letter,item: item.url_id } }">
                                                    Arbeit ansehen
                                                </router-link>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm"  v-if="class_list.class.item_count < 3">
                                <div class="card" >
                                    <div class="card-body text-center" style="text-transform: uppercase;background:#f1f1f1;display: flex;align-items: center;justify-content: center;min-height: 260px">
                                        <b>Keine weiteren Arbeiten vorhanden</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <share-collect></share-collect>
    </div>
</template>

<script>
    import TopSearch from "../components/TopSearch";
    import ShareCollect from "../components/ShareCollect";
    import axios from "axios";
    export default {
        name: 'ClassList',
        components: {ShareCollect, TopSearch},
        data: function () {
            return {
                loadComplete : false,
                classes: []
            }
        },
        methods:{
            getItems: function () {
                axios.get('https://api.pausenhof.de/class/list',
                    {headers:{'authorization': '3241451345'}}).then(response => {
                    this.classes = response.data.content;
                    this.loadComplete = true;
                });
            }
        },
        created () {
            this.getItems();
        },
    }
</script>

<style scoped>
    .searchWraper h1{
        font-family: 'Londrina Shadow';
        color:white;
        font-size: 3.5rem;
    }
    .letterWraper .btn-primary{
        background:#f76d6d;
        border-color:#f76d6d;
        color:#fff !important;
    }
    .letterWraper .btn-primary a{
        color:#fff !important;
    }
    .letterWraper .btn-primary a:hover{
        text-decoration: none !important;
    }
    .letterWraper{
        padding:25px 0;
    }

</style>

